export default function ArrowRight({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
      stroke={color}
    >
      <path
        d="M.75 12h22.5m-10.5 10.5L23.25 12 12.75 1.5"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  );
}
