export default function ArrowLeft({ width, height, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
      stroke={color}
    >
      <path
        d="M23.25 12H.75m10.5-10.5L.75 12l10.5 10.5"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
    </svg>
  );
}
