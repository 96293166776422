import React, { useState } from 'react';
import styled from '@emotion/styled';
import { navigate } from 'gatsby';
import { Flex, Box, Heading, Text, Button } from 'rebass';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import Root from '~components/Root';

const App = styled(Flex)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 185px);
  width: 100%;
  background-color: rgba(9, 30, 66, 0.3);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff),
    radial-gradient(circle at 0 0, #deebff, rgba(255, 255, 255, 0)),
    linear-gradient(to top, #deebff, #deebff, #b3d4ff);

  @media only screen and (max-width: 1280px) {
    height: calc(100vh - 144px);
  }
`;

const Modal = styled(Flex)`
  width: 100%;
  max-width: 604px;
  padding: 32px 32px;
  border-radius: 8px;
  box-shadow: 0 24px 48px 0 rgba(9, 30, 66, 0.15),
    0 0 12px 0 rgba(9, 30, 66, 0.05);
  background-color: #fff;
`;

const PrimaryButton = styled.button`
  appearance: none;
  width: 119px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  padding: 0;
  font-size: 16px;
  border-radius: 32px;
  background-color: ${props => props.theme.colors.orangeOrange120};
  color: ${props => props.theme.colors.neutralNeutral180};
  cursor: pointer;
  border: none;
  box-shadow: none;
  outline: none;
`;

const SecondaryButton = styled.button`
  appearance: none;
  width: 119px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  padding: 0;
  border-radius: 32px;
  background-color: #fff;
  border: 1px solid ${props => props.theme.colors.orangeOrange120};
  color: ${props => props.theme.colors.neutralNeutral180};
  cursor: pointer;
  margin-right: 48px;
  box-shadow: none;
  outline: none;
`;

const PathwayResume = ({ pageContext }) => {
  const [pathwayStage, setPathwayStage] = useLocalStorage(
    'pathway-stage',
    null
  );
  const [pathwaySection, setPathwaySection] = useLocalStorage(
    'pathway-section',
    null
  );
  const [pathwayPages, setPathwayPages] = useLocalStorage('pathway-pages', []);

  const [loading, setLoading] = useState(false);

  const reset = () => {
    setLoading(true);
    setPathwayStage(null);
    setPathwaySection(null);
    setPathwayPages([]);
    window.location.reload();
  };

  const goToPage = () => {
    if (pathwayStage && pathwaySection) {
      setLoading(true);
      const menu = pageContext.pathwayMenus.find(
        m => m.section === pathwaySection
      );
      const page = menu.items[pathwayStage - 1].items[0].page;
      const to = `/pathway/${pathwaySection}/${page.topic.slug.current}/${page.slug.current}`;
      navigate(to);
    }
  };

  return (
    <Root pageContext={pageContext}>
      <App>
        <Modal flexDirection="column">
          <Heading
            fontSize="28px"
            fontWeight="600"
            lineHeight="36px"
            color="#1a3092"
            textAlign="center"
            mb="19px"
          >
            Continue on your existing pathway or start over?
          </Heading>
          <Text fontSize="16px" lineHeight="24px" textAlign="center" mb="29px">
            Starting over will allow you to select a new pathway. Your previous
            pathway will longer be available.
          </Text>
          {loading ? (
            <Text
              textAlign="center"
              fontSize="16px"
              fontWeight="600"
              color="#1a3092"
            >
              Loading..
            </Text>
          ) : (
            <Flex
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <SecondaryButton onClick={e => reset()}>
                Start over
              </SecondaryButton>
              <PrimaryButton onClick={e => goToPage()}>Continue</PrimaryButton>
            </Flex>
          )}
        </Modal>
      </App>
    </Root>
  );
};

export default PathwayResume;
