export const responseObj = {
  option: 'default',
  question: 'What brings you to Autism: What next?',
  subQuestion: 'I’m here because…',
  responses: [
    {
      //pathway 1 and 2
      option: 'I’m interested in autism',
      question: 'What information do you need?',
      subQuestion: 'I want to find out about…',
      responses: [
        {
          option: 'Autism in children',
          question: 'What is your relationship with the person?',
          subQuestion: 'I am their…',
          responses: [
            {
              option: 'Parent',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
              ],
            },
            {
              option: 'Carer',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
              ],
            },
            {
              option: 'Grandparent',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
              ],
            },
            {
              option: 'Sibling',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
              ],
            },
            {
              option: 'Family friend',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          option: 'Autism in adults',
          question: "How would you describe the stage you're at?",
          subQuestion: "I'd describe it as…",
          responses: [
            {
              option: 'New to autism',
              stage: '1',
              section: 'adults',
            },
            {
              option: 'Seeking a diagnosis',
              stage: '2',
              section: 'adults',
            },
            {
              option: 'Recently diagnosed',
              stage: '3',
              section: 'adults',
            },
            {
              option: 'Diagnosed in the last year',
              stage: '4',
              section: 'adults',
            },
            {
              option: 'Learning to live with autism',
              stage: '5',
              section: 'adults',
            },
          ],
        },
      ],
    },

    {
      //pathway 3 and 4
      option: 'I have a loved one with autism',
      question: 'What information do you need?',
      subQuestion: 'I want to find out about…',
      responses: [
        {
          option: 'Autism in children',
          question: 'What is your relationship with the person?',
          subQuestion: 'I am their…',
          responses: [
            {
              option: 'Parent',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
              ],
            },
            {
              option: 'Carer',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
              ],
            },
            {
              option: 'Grandparent',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
              ],
            },
            {
              option: 'Sibling',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
              ],
            },
            {
              option: 'Family friend',
              question: 'What age is the child?',
              subQuestion: 'They are…',
              responses: [
                {
                  option: '0-6 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '7-12 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '13-16 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                      responses: [],
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                      responses: [],
                    },
                  ],
                },
                {
                  option: '16-18 years old',
                  question: "How would you describe the stage you're at?",
                  subQuestion: "I'd describe it as…",
                  responses: [
                    {
                      option: 'New to autism',
                      stage: '1',
                      section: 'children',
                    },
                    {
                      option: 'Seeking a diagnosis',
                      stage: '2',
                      section: 'children',
                    },
                    {
                      option: 'Recently diagnosed',
                      stage: '3',
                      section: 'children',
                    },
                    {
                      option: 'Diagnosed in the last year',
                      stage: '4',
                      section: 'children',
                    },
                    {
                      option: 'Learning to live with autism',
                      stage: '5',
                      section: 'children',
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          option: 'Autism in adults',
          question: 'What is your relationship with the person?',
          subQuestion: 'I am their…',
          responses: [
            {
              option: 'Parent',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
            {
              option: 'Carer',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
            {
              option: 'Grandparent',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
            {
              option: 'Sibling',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
            {
              option: 'Family friend',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
            {
              option: 'Husband',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
            {
              option: 'Wife',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
            {
              option: 'Partner',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
            {
              option: 'Friend',
              question: "How would you describe the stage they're at?",
              subQuestion: "I'd describe it as…",
              responses: [
                {
                  option: 'New to autism',
                  stage: '1',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Seeking a diagnosis',
                  stage: '2',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Recently diagnosed',
                  stage: '3',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Diagnosed in the last year',
                  stage: '4',
                  section: 'adults',
                  responses: [],
                },
                {
                  option: 'Learning to live with autism',
                  stage: '5',
                  section: 'adults',
                  responses: [],
                },
              ],
            },
          ],
        },
      ],
    },

    {
      // pathway 5 & 6
      option: 'I’m wondering if I have autism',
      question: "How would you describe the stage you're at?",
      subQuestion: "I'd describe it as...",
      responses: [
        {
          option: 'New to autism',
          stage: '1',
          section: 'adults',
          responses: [],
        },
        {
          option: 'Seeking a diagnosis',
          stage: '2',
          section: 'adults',
          responses: [],
        },
        {
          option: 'Recently diagnosed',
          stage: '3',
          section: 'adults',
          responses: [],
        },
        {
          option: 'Diagnosed in the last year',
          stage: '4',
          section: 'adults',
          responses: [],
        },
        {
          option: 'Learning to live with autism',
          stage: '5',
          section: 'adults',
          responses: [],
        },
      ],
    },
    {
      // pathway 7 & 8
      option: "I'm waiting for my child's assessment",
      question: 'What information do you need?',
      subQuestion: 'I want to find out about…',
      responses: [
        {
          option: 'Autism in children',
          question: 'What is your relationship with the person?',
          subQuestion: 'I am their...',
          responses: [
            {
              option: 'Parent',
              question: 'What age is the child?',
              subQuestion: 'They are between…',
              responses: [
                {
                  option: '0-6 years old',
                  stage: '2',
                  section: 'children',
                },
                {
                  option: '7-12 years old',
                  stage: '2',
                  section: 'children',
                },
                {
                  option: '13-16 years old',
                  stage: '2',
                  section: 'children',
                },
                {
                  option: '16-18 years old',
                  stage: '2',
                  section: 'children',
                },
              ],
            },
            {
              option: 'Carer',
              question: 'What age is the child?',
              subQuestion: 'They are between…',
              responses: [
                {
                  option: '0-6 years old',
                  stage: '2',
                  section: 'children',
                },
                {
                  option: '7-12 years old',
                  stage: '2',
                  section: 'children',
                },
                {
                  option: '13-16 years old',
                  stage: '2',
                  section: 'children',
                },
                {
                  option: '16-18 years old',
                  stage: '2',
                  section: 'children',
                },
              ],
            },
          ],
        },
        {
          option: 'Autism in adults',
          question: 'What is your relationship with the person?',
          subQuestion: 'I am their...',
          responses: [
            {
              option: 'Parent',
              question: 'What age is the child?',
              subQuestion: 'They are between…',
              responses: [
                {
                  option: '18-24 years old',
                  stage: '2',
                  section: 'adults',
                },
                {
                  option: 'Over 24 years old',
                  stage: '2',
                  section: 'adults',
                },
              ],
            },
            {
              option: 'Carer',
              question: 'What age is the child?',
              subQuestion: 'They are between…',
              responses: [
                {
                  option: '18-24 years old',
                  stage: '2',
                  section: 'adults',
                },
                {
                  option: 'Over 24 years old',
                  stage: '2',
                  section: 'adults',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      // pathway 9 & 10
      option: 'My child was recently diagnosed',
      question: 'What information do you need?',
      subQuestion: 'I want to find out about…',
      responses: [
        {
          option: 'Autism in children',
          question: 'What is your relationship with the person?',
          subQuestion: 'I am their...',
          responses: [
            {
              option: 'Parent',
              question: 'What age is the child?',
              subQuestion: 'They are between…',
              responses: [
                {
                  option: '0-6 years old',
                  stage: '3',
                  section: 'children',
                },
                {
                  option: '7-12 years old',
                  stage: '3',
                  section: 'children',
                },
                {
                  option: '13-16 years old',
                  stage: '3',
                  section: 'children',
                },
                {
                  option: '16-18 years old',
                  stage: '3',
                  section: 'children',
                },
              ],
            },
            {
              option: 'Carer',
              question: 'What age is the child?',
              subQuestion: 'They are between…',
              responses: [
                {
                  option: '0-6 years old',
                  stage: '3',
                  section: 'children',
                },
                {
                  option: '7-12 years old',
                  stage: '3',
                  section: 'children',
                },
                {
                  option: '13-16 years old',
                  stage: '3',
                  section: 'children',
                },
                {
                  option: '16-18 years old',
                  stage: '3',
                  section: 'children',
                },
              ],
            },
          ],
        },
        {
          option: 'Autism in adults',
          question: 'What is your relationship with the person?',
          subQuestion: 'I am their...',
          responses: [
            {
              option: 'Parent',
              question: 'What age is the child?',
              subQuestion: 'They are between…',
              responses: [
                {
                  option: '18-24 years old',
                  stage: '3',
                  section: 'adults',
                },
                {
                  option: 'Over 24 years old',
                  stage: '3',
                  section: 'adults',
                },
              ],
            },
            {
              option: 'Carer',
              question: 'What age is the child?',
              subQuestion: 'They are between…',
              responses: [
                {
                  option: '18-24 years old',
                  stage: '3',
                  section: 'adults',
                },
                {
                  option: 'Over 24 years old',
                  stage: '3',
                  section: 'adults',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      // pathway 11
      option: 'I am autistic',
      question: "How would you describe the stage you're at?",
      subQuestion: "I'd describe it as...",
      responses: [
        {
          option: 'New to autism',
          stage: '1',
          section: 'adults',
          responses: [],
        },
        {
          option: 'Seeking a diagnosis',
          stage: '2',
          section: 'adults',
          responses: [],
        },
        {
          option: 'Recently diagnosed',
          stage: '3',
          section: 'adults',
          responses: [],
        },
        {
          option: 'Diagnosed in the last year',
          stage: '4',
          section: 'adults',
          responses: [],
        },
        {
          option: 'Learning to live with autism',
          stage: '5',
          section: 'adults',
          responses: [],
        },
      ],
    },
  ],
};
